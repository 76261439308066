interface WorkerConfig {
    keepaliveUrl: string;
    companionUrl: string;
}

interface QLabsCompanionConfig {
    enableBMKeepAlive?: boolean;
}

declare global {
    interface Window {
        __qlabsConfig: WorkerConfig;
    }
}
export {};

console.log("[QLABS] Companion Script Loaded");

const INTERVAL = 30000;

// Function to fetch data from the URL
async function keepalive(keepaliveUrl: string) {
    try {
        const response = await fetch(keepaliveUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            credentials: "include",
        });
        console.log("[QLABS] BM KeepAlive");
    } catch (error) {
        console.error(
            "[QLABS] Error with BM keep alive; are you logged into business manager?",
            error,
        );
    }
}

var keepAliveInterval;

function getCompanionConfig(): QLabsCompanionConfig {
    const config = JSON.parse(
        window.localStorage.getItem("qlabs-companion-config") ?? "{}",
    );
    return config;
}

// Set up the interval to fetch data
function setupKeepAliveInterval() {
    const config = getCompanionConfig();
    if (window?.__qlabsConfig?.keepaliveUrl && config.enableBMKeepAlive) {
        keepAliveInterval = setInterval(
            () => keepalive(window.__qlabsConfig.keepaliveUrl),
            INTERVAL,
        );
    } else {
        console.log("[QLABS] BM KeepAlive Disabled");
    }
}

setupKeepAliveInterval();
